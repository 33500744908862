<template>
    <div>
        <div class="col-md-12">
            <div class="row" style="margin-top: 20px">
                <div class="col-md-6">
                    <h3 class="page-header">
                        <i class="fa fa fa-calculator animated bounceInDown show-info"></i>
                        <span>Over Dues-Dated Report</span>
                    </h3>
                </div>
                <div class="col-md-6 text-right" v-if="viewno == 1">
                    <button @click="refresh()" class="btn btn-refresh">
                        <i class="fa fa-refresh"></i>
                    </button>
                </div>
                <div class="col-md-6 text-right" v-if="viewno == 2">
                    <button @click="back()" class="btn btn-primary">
                        <i class="fa fa-backward"></i> Back
                    </button>
                </div>
                <!-- <div class="col-md-6 text-right">
                    <button @click="refresh()" class="btn btn-refresh">
                        <i class="fa fa-refresh"></i>
                    </button>
                </div> -->
            </div>
        </div>

        <div  v-if="viewno==1">
            <div class="col-md-12">
                <div class="panel panel-cascade">
                    <div class="panel-body ">                   
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-horizontal cascde-forms">
                                    <div class="form-group CHK row">
                                        <label class="col-lg-4 col-md-3 control-label">Customer Name</label>
                                        <div class="col-lg-8 col-md-9">
                                            <input class="form-control form-cascade-control input-small" v-model="customername" type="text"  />
                                        </div>
                                    </div>
                                    <!-- <div class="form-group row">
                                        <label class="col-lg-4 col-md-3 control-label">Zone<span style="color:rgba(255, 0, 0, 0.67)">*</span></label>
                                        <div class="col-lg-8 col-md-9">
                                            <select class="form-control form-cascade-control input-small" v-model="zoneid">
                                                <option selected="selected" value="">SELECT</option>
                                                <option v-for="item in getmastertype(MASTER_ZONE_TYPES)" :key="item.labelid" :value="item.labelid">{{item.label}}</option>
                                            </select>
                                        </div>
                                    </div> -->
                                    <div class="form-group row">
                                        <label class="col-lg-4 col-md-3 control-label">Emi Date: FROM</label>
                                        <div class="col-lg-8 col-md-9">
                                            <input class="form-control form-cascade-control input-small" v-model="emidatefrom" type="date" />
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-lg-4 col-md-3 control-label">Due Date :FROM</label>
                                        <div class="col-lg-8 col-md-9">
                                            <input class="form-control form-cascade-control input-small" v-model="duedatefrom" type="date"/>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-lg-4 col-md-3 control-label">Customer LAN No.</label>
                                        <div class="col-lg-8 col-md-9">
                                            <input class="form-control form-cascade-control input-small" v-model="manualid" type="text" />
                                        </div>
                                    </div>
                                    <!-- <div class="form-group row">
                                        <label class="col-lg-4 col-md-3 control-label">Bucket: FROM</label>
                                        <div class="col-lg-8 col-md-9">
                                            <input class="form-control form-cascade-control input-small" v-model="bucketdatefrom" type="text"/>
                                        </div>
                                    </div> -->
                                    <!-- <div class="form-group row">
                                        <label class="col-lg-4 col-md-3 control-label">Remaining: FROM</label>
                                        <div class="col-lg-8 col-md-9">
                                            <input class="form-control form-cascade-control input-small" v-model="remfrom" type="number"/>
                                        </div>
                                    </div> -->
                                </div>
                            </div>
                            <div class="col-md-6 CHK">
                                <div class="form-horizontal cascde-forms">
                                    <div class="form-group CHK  row">
                                    <label class="col-lg-4 col-md-3 control-label">Customer Mobile</label>
                                        <div class="col-lg-8 col-md-9">
                                            <input class="form-control form-cascade-control input-small" v-model="mobile" type="number"  />
                                        </div>
                                    </div>
                                
                                    <div class="form-group row">
                                        <label class="col-lg-4 col-md-3 control-label">Emi Date : TO</label>
                                        <div class="col-lg-8 col-md-9">
                                            <input class="form-control form-cascade-control input-small" v-model="emidateto" type="date"  />
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-lg-4 col-md-3 control-label">Due Date : TO</label>
                                        <div class="col-lg-8 col-md-9">
                                            <input class="form-control form-cascade-control input-small" v-model="duedateto" type="date" />
                                        </div>
                                    </div>
                                    <!-- <div class="form-group row">
                                        <label class="col-lg-4 col-md-3 control-label">Bucket : TO</label>
                                        <div class="col-lg-8 col-md-9">
                                            <input class="form-control form-cascade-control input-small" v-model="bucketdateto" type="text" />
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-lg-4 col-md-3 control-label">Remaining : TO</label>
                                        <div class="col-lg-8 col-md-9">
                                            <input class="form-control form-cascade-control input-small" v-model="remto" type="number" />
                                        </div>
                                    </div> -->
                                    <div class="form-group">
            
                                        <div class="col-lg-12 col-md-9 flex-around-row">
                                            <button class="btn btn-primary" @click="refresh()">Search</button>
                                            <button class="btn btn-danger" @click="cancel()">Cancel</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="loginusercheck(162)" class="">
                            <div class="info-box bg-warning text-white">
                                <div class="info-icon bg-primary-dark" style="padding-bottom:5px;">
                                    <h5>
                                        <i class="fa fa-check-square-o"></i>
                                        Select REC BY
                                    </h5>
                                </div>
                            </div>
                            <div class="col-md-12 ">
                                <div class="col-md-12">
                                    <div class="row">
                                        <div class="col-md-3" v-for="(item) in employees" v-bind:key="item.id">
                                            <!-- <usercheck :empid="item.id"></usercheck>  {{item.name}} -->
                                            <input type="checkbox" :value="item.id" id="item.id" v-model="checkedCategories"
                                                @change="check($event)">{{ item.name }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    
            <div class="col-md-12">
            
                <div class="col-md-12 mt-20">
                    <div class="col-md-12 row">
                        <div class="col-md-2">
                            <label for="">Total Rec. :</label>
                                <span class="badge bg-seagreen"> {{ overduescount }} </span>
                        </div>
                        <div class="col-md-4">
                            <label for="">Total Dues Amount :</label>
                            <span class="badge bg-seagreen">  <i class="fa fa-inr"></i>{{ totaldues }}</span>
                        </div>
                        <div class="col-md-4">
                            <label for="">Total Remaining Amount :</label>
                            <span class="badge bg-danger">  <i class="fa fa-inr"></i>{{ totalremaining }}</span>
                        </div>
                        <div class="col-md-2"></div>
                    </div>
                    <div class="flex-between-row mt-20" >
                        <div class="col-md-3 flex-between-row">
                            <div class="">
                                <strong>Show Rec.</strong>
                            </div>
                            <div>
                                <select class="form-control col-mb-1" v-model="noofrec" id="rec" @change="refresh()">
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </select>
                            </div>
                        </div>
                        
                        <!-- <div class="flex-between-row">
                            <label for="">Search Lan</label>
                            <input type="text" class="" v-model="loanaccount">
                            <button class="btn btn-success" @click="refresh()"><i class='bx bx-search-alt-2'></i></button>
                        </div> -->

                    </div>

                </div>
                <div class="panel">
                    <div class="table-responsive panel-body">
                        <table class="table users-table table-condensed table-hover table-sm table-bordered">
                            <thead class="table-font">
                                <tr>
                                    <th>#</th>
                                    <th>LAN No.</th>
                                    <th>Customer Name</th>
                                    <th>Contact</th>
                                    <th>Address</th>
                                    <th>Emi Amt</th>
                                    <th>Rem. Amt</th>
                                    <th>Emi Date</th>
                                    <th>Due Date</th>
                                    <th>Day of Delay</th>
                                    <th>Follow By</th>
                                    <th>Conversation</th>
                                </tr>
                            </thead>

                            <over-dues-row v-for="(item, index) in overdues" :key="item.id" :item="item" :index="index"
                                :currentpage="currentpage" :noofrec="noofrec"></over-dues-row>
                        </table>
                    </div>
                </div>
                <div class="col-md-12" style="margin-top: 20px">
                    <nav aria-label="Page navigation example" style="text-align: right !important">
                        <paginate style="margin: 0px !important; float: right" :page-count="noofpages"
                            :click-handler="clickCallback" :prev-text="'&laquo;'" :next-text="'&raquo;'"
                            :container-class="'pagination'">
                        </paginate>
                    </nav>
                </div>
            </div>
        </div>
        <sales-details v-if="viewno ==2 "></sales-details>
    </div>
</template>
<script>
import OverDuesRow from './OverDuesRow.vue';
import Paginate from "vuejs-paginate";
import { mapGetters } from "vuex";
import Constants from '../../components/utilities/Constants.vue';
import SalesDetails from '../salesmanager/SalesDetails.vue';
export default {
    mixins: [Constants],
    components: { OverDuesRow, Paginate,SalesDetails },
    data() {
        return {
            noofrec: 10,
            overdues: [],
            currentpage: 1,
            overduescount: 0,
            loanaccount: '',
            checkedCategories: [],
            mobile:'',
            customername:'',
            manualid:'',
            emidateto:'',
            emidatefrom:'',
            duedatefrom:'',
            duedateto:'',
            totaldues:0,
            totalremaining:0
        }






    },
    mounted() {
        this.$store.dispatch('fetchemployees')
        this.refresh();
    },
    computed: {
        ...mapGetters(["edititem", "viewno", "scheduleloanemicount", "scheduleloanemi", "loggedinuser",
            "employees", "attributes", "accountstatus", "loanaccounttype", "sumofremainingamount"]),
        noofpages() {
            return Math.ceil(parseInt(this.overduescount) / parseInt(this.noofrec));
        },
    },
    methods: {
        clickCallback(currentpage) {
            this.currentpage = currentpage;
            this.$store.commit("assigncurrentpage", currentpage);
            this.refresh();
        },
        check(e) {
            this.$nextTick(() => {
                console.log(this.checkedCategories, e)
            })
            this.refresh()
        },
        refresh() {
            this.$store.commit('assignloadingstatus', 1)
            setTimeout(() => {
                if (!this.loginusercheck(162)) {
                    console.log("entered in getloggedin")
                    console.log(this.loggedinuser?.id)
                    if (this.checkedCategories.length > 0) {
                        this.checkedCategories.forEach(element => {
                            if (!element == this.loggedinuser.id) {
                                this.checkedCategories.push(this.loggedinuser?.id)
                            }
                        });
                    } else {
                        this.checkedCategories.push(this.loggedinuser?.id)
                    }
                    console.log(this.checkedCategories)
                }

                let param = {
                    noofrec: this.noofrec, currentpage: this.currentpage, loanaccount: this.loanaccount,
                    empid: this.checkedCategories, customername: this.customername, mobile: this.mobile,
                    emidateto: this.emidateto, emidatefrom: this.emidatefrom, duedatefrom: this.duedatefrom,
                    duedateto: this.duedateto,manualid: this.manualid
                }
                window.axios.post('api/overdues/left/comment', param)
                    .then((response) => this.processTotaltResponse(response.data))
                    .catch((err) => {
                        console.log(err)
                    });
            }, 2000);
        },
        processTotaltResponse(data) {
            this.$store.commit('assignloadingstatus', 0)
            this.overdues = data.overdues
            this.overduescount = data.count
            this.totaldues = data.totaldues
            this.totalremaining = data.totalremaining
        },
        cancel(){
            this.customername= '',
            this.mobile=''
            this.emidatefrom=''
            this.emidateto=''
            this.duedatefrom=''
            this.duedateto=''
            this.manualid=''
            this.refresh()
        },
        // getremaining(){
        //     let total=0;
        //     this.totalremaining.forEach(function(item){
        //         total += parseFloat(item.loanaccount.remainingamount)
        //     });
        //     return total.toFixed(2);
        // }, 
        back() {
            this.$store.commit("assignviewno", 1);
        },
        
    },
}
</script>

